.slick-slider{
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    img{
        margin: 0 !important;
        padding: 0 !important;
    }
}
.slick-arrow{
    position: absolute;
}
.slick-next,.slick-prev{
    z-index: 5;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    height: 100%;
    border: none;
    background: transparent;
    -webkit-appearance: none;
    outline: none;
    color: transparent;
    cursor: e-resize;
    // cursor: url('../images/arrow.png') 25 19, auto;
    // cursor: -webkit-image-set(url('../images/arrow.png') 1x, url('../images/arrow@2x.png') 2x) 25 19, pointer; // Webkit
}
.slick-prev{
    right: auto;
    left: 0;
    cursor: w-resize;
    // cursor: url('../images/arrow_l.png') 25 19, auto;
    // cursor: -webkit-image-set(url('../images/arrow_l.png') 1x, url('../images/arrow_l@2x.png') 2x) 25 19, pointer; // Webkit
}