@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

$white: rgb(255,255,255);
$grey: rgb(204,204,204);
$black: rgb(0,0,0);

$font-size: 16px;
$font-size-medium: 21px;
$font-size-big: 28px;

$font-size-1000: 16px;
$font-size-medium-1000: 16px;
$font-size-big-1000: 28px;

$font-size-small: 18px;
$font-size-medium-small: 18px;
$font-size-big-small: 24px;


$right-cont-width: 325px;
$header-bar-height: 50px;

$mq-small: 669px;
$mq-1000: 1000px;

@import 'normalize';
@import 'slick';
@import 'slick_theme';
// @import 'kirby';
@import 'preloader';


@font-face {
	font-family:'fff';
	src: url('../fonts/fff-RegularItalic_gdi.eot');
	src: url('../fonts/fff-RegularItalic_gdi.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/fff-RegularItalic_gdi.woff') format('woff'),
		 url('../fonts/fff-RegularItalic_gdi.svg#fff-RegularItalic') format('svg');
	font-weight: 400;
	font-style: italic;
	font-stretch: normal;
	unicode-range: U+000D-FB04;
}
@font-face {
	font-family:'fff';
	src: url('../fonts/fff-Regular_gdi.eot');
	src: url('../fonts/fff-Regular_gdi.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/fff-Regular_gdi.woff') format('woff'),
		 url('../fonts/fff-Regular_gdi.svg#fff-Regular') format('svg');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+000D-FB04;
}
a{
	font-style: italic;
	text-decoration: none;
	color: inherit;
}
.font-small{
	font-size: $font-size;
	letter-spacing: 0.025em;
	line-height: 1.4em;
}
.font-medium{
	font-size: $font-size-medium;
	font-weight: 400;
	line-height: 1.35em;
	letter-spacing: 0.02em;

}
.font-big{
	font-size: $font-size-big;
	letter-spacing: 0.015em;
}
@media screen and (max-width: $mq-1000) {
	.font-small{
		font-size: $font-size-1000;
	}
	.font-medium{
		font-size: $font-size-medium-1000;
	}
	.font-big{
		font-size: $font-size-big-1000;
	}
}
@media screen and (max-width: $mq-small) {
	.font-small{
		font-size: $font-size-small;
	}
	.font-medium{
		font-size: $font-size-medium-small;
	}
	.font-big{
		font-size: $font-size-big-small;
	}
}
h2{
	margin: 0;
	padding: 0;
}
p{
	margin: 0;
	padding: 0;
	padding-bottom: 1em;
	&:last-of-type{
		padding-bottom: 0;
	}
}
img{
	max-width: 100%;
}
ul{
	list-style-type: none;
	padding: 0;
	margin: 0;
}
strong{
	font-weight: 400;
}
.logo{
	width: 40px;
	height: 40px;
}
.icon{
	width: 20px;
	height: 20px;
}
.visually-hidden{
	display: none;
}
@media screen and (max-width: $mq-small) {
	.mobile-hidden{
		display: none !important;
	}
}
body{
	padding: 0;
	max-width: none;
	font-family: 'fff';
	font-weight: 400;
	font-style: normal;
	font-size: $font-size;
	width: 100%;
	height: 100%;
}
*::-moz-selection { color: $black; background-color: rgba(0,0,0,0.99); }
*::selection { color: $black; background-color: rgba(0,0,0,0.99); }
.bodywrapper{
	overflow: hidden;
	width: 100%;
	height: 100%;
}
.wrappercontainer{
	height: 100%;
}
html{
	height: 100%;
}
.maincontainer{
	width: 100vw;
	max-height: calc(100% - 52px);
	overflow: hidden;
	display: block;
	height: calc(100% - 52px);
	margin-top: 52px;
}

// containers
.leftcontainer{
	min-height: 100%;
	width: calc(100% - #{$right-cont-width});
	float: left;
	display: block;
	max-height: calc(100% - 52px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    a{
		color: $black;
		&:hover{
			color: $grey;
		}
	}
}
.project-container--wrapper{
	padding: 0 20px;
	max-width: 1500px;
}
.project-container--description{
	 &:after {
	    content: "";
	    display: table;
	    clear: both;
  	}
}
@media screen and (min-width: $mq-small){
	.project-container--description{
		padding-bottom: 20px;
	}
	.project-container--images{
		img{
			padding-bottom: 20px;
			&:first-of-type{
				padding-top: 20px;
			}
		}
	}
}
.project-container--images{
	img{
		display: block;
		width: 100%;
		// padding: 0 20px 20px 20px;
		box-sizing: border-box;
	}
}
@media screen and (max-width: $mq-small) {
	.project-container--images{
		img{
			padding-bottom: 10px;
		}
	}
	.project-container--wrapper{
		padding: 0 10px;
	}
	.left-visible{
		.rightcontainer{
			*{
				pointer-events: none;
			}
		}
	}
	.right-visible{
		.leftcontainer{
			*{
				pointer-events: none;
			}
		}
	}
}

.rightcontainer{
	min-height: 100%;
	position: relative;
	width: $right-cont-width;
	float: left;
	display: block;
	max-height: calc(100% - 52px);
	overflow-y: auto;
	box-sizing: border-box;
	padding-left: 15px;
	padding-right: 15px;
	border-left: 2px solid $black;
	-webkit-overflow-scrolling: touch;
	padding-top: 1em;
}
.container--description-left, .container--description-right{
	float:left;
	box-sizing: border-box;
}
@media screen and (min-width: $mq-small) {
	.container--description-left{
		padding-right: 20px;
	}
}
@media screen and (max-width: $mq-1000) {
	.container--description-left, .container--description-right{
		width: 100%!important;
	}
	.container--description-left{
		padding-bottom: 1em;
	}
}
.container--description-left{
	width: calc(100% - #{$right-cont-width});
}
.container--description-right{
	width: $right-cont-width;
}
.rightcontainer-row{
	width: 50%;
	display: block;
	float: left;
}
.project-container{
	list-style-type: none;
	display: block;
	box-sizing: border-box;
	padding: 5px;
	padding-bottom: 9px;
	cursor: pointer;
	img{
		filter: grayscale(100%);
		-webkit-filter: grayscale(100%);
		width: 100%;
	}
	&:hover{
		img{
			filter: grayscale(0);
			-webkit-filter: grayscale(0);
		}
	}
	&.active{
		img{
			filter: grayscale(0);
			-webkit-filter: grayscale(0);
		}
	}
}
.project-list{
	a{
		font-style: normal;
	}
}
.project-list--title{
	padding-left: 5px;
	padding-bottom: 7px;
}
.project-title{
	display: inline-block;
	line-height: 1.25em;
}

// Header
header{
	width: 100%;
	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	background-color: $grey;
	transform: translateY(-100%) translateY($header-bar-height+2);
}
.maincontainer-header{
	padding: 20px 0;
	&:after {
    	content: "";
    	display: table;
    	clear: both;
  	}
  	a{
  		&:hover{
  			color: $white;
  		}
  	}
}
.maincontainer-header--first,.maincontainer-header--second,.maincontainer-header--third{
	float: left;
}
.maincontainer-header--first{
	display: block;
	width: calc(100% - 650px);
	padding: 0 20px;
	box-sizing: border-box;
}
.maincontainer-header--second{
	display: block;
	width: $right-cont-width;
	padding: 0 20px;
	box-sizing: border-box;
}
.maincontainer-header--third{
	display: block;
	width: $right-cont-width;
	padding: 0 20px;
	box-sizing: border-box;
}

@media screen and (max-width: $mq-1000) {
	.maincontainer-header--second,.maincontainer-header--third,.maincontainer-header--first{
		width: 33.33333%;
	}
}
@media screen and (max-width: $mq-small) {
	.maincontainer-header--second,.maincontainer-header--third,.maincontainer-header--first{
		width: 100%;
		padding-bottom: 1em;
	}
	header{
		height: 100%;
	}
	.maincontainer-header{
		max-height: calc(100% - #{$header-bar-height+2});
		overflow-y: scroll;
		box-sizing: border-box;
		-webkit-overflow-scrolling: touch;
	}
}

// TEAM
.team-member{
	padding-bottom: 1em;
	position: relative;
	&:last-of-type{
		padding-bottom: 0;
	}
	img{
		position: absolute;
		right: calc(100% + 20px);
		top: 0;
		display: none;
		max-width: 200px;
		z-index: 1;
	}
	&:hover{
		img{
			display: block;
		}
	}
}


.header-bar{
	width: 100%;
	text-align: center;
	border-bottom: 2px solid $black;
	border-top: 2px solid $black;
	box-sizing: border-box;
	cursor: pointer;
	&:after {
	    content: "";
	    display: table;
	    clear: both;
  	}
}
@media screen and (max-width: $mq-small) {
	.header-bar{
		margin-top: -2px;
	}
}

.header-bar--section{
	display: block;
	float: left;
	width: 33.33333%;
	white-space: nowrap;
	padding: 0 20px;
	line-height: $header-bar-height;
	box-sizing: border-box;
	position: relative;
	&.header-left{
		text-align: left;
	}
	&.header-center{
		text-align: center;
	}
	&.header-right{
		text-align: right;
	}
}

.headertoggle{
	display: inline-block;
	position: absolute;
	right: 20px;
	top: 0;
	transition: transform 0.2s 0.6s ease;
	text-align: center;
	&.is-toggled{
		transition: transform 0.2s 0.4s ease;
		transform: rotateY(90deg);
	}
}
.closeicon{
	height: 23px;
	margin-bottom: -2px;
}
// Animations

header,.pre-lotion,.maincontainer{
	transition: transform 1.2s ease, opacity 0s 1.2s ease;
}

body{
	.pre-lotion{
		transform: translateX(-100%);
		opacity: 0;
		pointer-events: none;
	}
	.maincontainer{
		transform: translateX(0);
	}
	header{
		transform: translateY(-100%) translateY($header-bar-height+2);
	}
}
body.preloader-visible{
	overflow: hidden;
	.pre-lotion{
		transform: translateX(0);
		opacity: 1;
		pointer-events: auto;
	}
	.maincontainer{
		transform: translateX(100%);
	}
	header{
		transform: translateX(100%) translateY(calc(-100% + #{$header-bar-height+2}));
	}
}



// MEIDA QUERIES
@media screen and (max-width: $mq-small) {
	.header-center{
		display: none;
	}
	.header-left{
		width: 66.6666%;
	}
	.wrappercontainer{
		width: 160%;
		transition: transform 0.6s ease;
		&.right-visible{
			transform: translateX(-37.5%);
		}
		&.left-visible{
			transform: translateX(0);
		}
	}
	.leftcontainer{
		padding: 10px 0;
		box-sizing: border-box;
		width: 50%;
	}
	.rightcontainer{
		width: 50%;
		padding: 10px 5px;
		padding-top: 0.4em;
	}
}