// PRELOADER

.playground-container{
	position: absolute;
	display: inline-block;
	.jsgif{
		position: absolute;
		top:50%;
		left:50%;
		transform: translateX(-50%) translateY(-50%) scale(1.5);
		pointer-events: none;
		max-width: 140%;
		canvas{
			width: 100%;
		}
	}
	&:nth-child(1){
		z-index: 110;
	}
	&:nth-child(2){
		z-index: 109;
	}
	&:nth-child(3){
		z-index: 108;
	}
	&:nth-child(4){
		z-index: 107;
	}
	&:nth-child(5){
		z-index: 106;
	}
	&:nth-child(6){
		z-index: 105;
	}
	&:nth-child(7){
		z-index: 104;
	}
	&:nth-child(8){
		z-index: 103;
	}
	&:nth-child(9){
		z-index: 102;
	}
	&:nth-child(10){
		z-index: 101;
	}
}
.exp-lotion-container{
	opacity: 1;
	position: absolute;
	top: 0;
	left: 0;
	pointer-events: none;
	z-index: 105;
}
.pre-lotion{
	z-index: 100;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	width: 100vw;
	height: 100vh;
	background-color: $white;
	box-sizing: border-box;
	cursor: url(../images/bomb.png) 20 20, auto;
    cursor: -webkit-image-set(url("../images/bomb.png") 1x, url("../images/bomb@2x.png") 2x) 0 0, pointer; // Webkit
}
.enter_the_wu-tang{
	z-index: 500;
	font-size: 24px;
	line-height: 30px;
	position: absolute;
	right: -2px;
	top: 0;
	bottom: 0;
	height: 100%;
	height: 100vh;
	width: 50px;
	background-color: $grey;
	cursor: pointer;
	border-left: 2px solid black;
	border-right: 2px solid black;
	span{
		text-align: center;
		display: inline-block;
		width: 100%;
	}
	.enter_placeholder{
		height: 1em;
		width: 100%;
	}
	.enter_textwrapper{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
}